import './App.css';
import one from './pics/Gym Warrios Product Image 1.png'
import two from './pics/Fitness Champion Product Image 2.png'
import three from './pics/Elite Athlete Product Image 3.png'
import logo from './pics/Logo.jpg'
import title from './pics/WebTitle.png'

function App() {
    return <div>
        <section class="top">
            <img></img>
        </section>
        <section class="header">
            <img src={logo} width="150" height="75" />
            <img class="headimg" src={title} width="1200" height="200" />  
            <p>Introducing the Dani K Elite Access Loyalty Program: our way of saying thank you for your steadfast support. With three tiers of discounts and promos, we are celebrating your loyalty with exclusive benefits. By partnering with local businesses, we're championing holistic health and community wellness, embodying the spirit of giving back. Join us as we embrace every aspect of our lives together.</p>
            <h4><b>*THE DEAL OFFERS OF EACH PACKAGE ARE AVAILABLE FOR A FULL YEAR*</b></h4>     
            <h4>*Nonmembers of DANI K GYM & WELLNESS are eligible. If you would like to purchase a membership, we will waive the $60 enrollment fee with the purchase of one of these packages.*</h4>
        </section>
        <section class="productline">
        <section class="product">
            {/*<section class="head">
                <h2>Gym Warrior</h2>
</section>*/}
            <section class="prodimg">
                <img class="prodimg" src={one} width="450" height="450" />
            </section>
            <section class="button">
                <button><a href='https://buy.stripe.com/eVag099eu2TqfbG7ss' target="_blank">Purchase</a></button>
            </section>
        </section>
        <section class="product">
            {/*<section class="head">
                <h2>Fitness Champion</h2>
</section>*/}
            <section class="prodimg">
                <img class="prodimg" src={two} width="450" height="450" />
            </section>
            <section class="button">
                <button><a href='https://buy.stripe.com/4gw29j3Uady47JedQR' target="_blank">Purchase</a></button>
            </section>
        </section>
        <section class="product">
            {/*<section class="head">
                <h2>Elite Athlete</h2>
</section>*/}
            <section class="prodimg">
                <img class="prodimg" src={three} width="450" height="450" />
            </section>
            <section class="button">
                <button width="300" height="100"><a href='https://buy.stripe.com/5kA15fgGW65CaVq6oq' target="_blank">Purchase</a></button>
            </section>
        </section>
        </section>
    </div>
}

export default App;
